$OpenSansPath: "/assets/opensans";

@font-face {
  font-family: 'Open Sans';
  src: url('#{$OpenSansPath}/OpenSans-Regular.eot');
  src: url('#{$OpenSansPath}/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
       url('#{$OpenSansPath}/OpenSans-Regular.woff') format('woff'),
       url('#{$OpenSansPath}/OpenSans-Regular.ttf') format('truetype'),
       url('#{$OpenSansPath}/OpenSans-Regular.svg#OpenSansRegular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('#{$OpenSansPath}/OpenSans-Italic.eot');
  src: url('#{$OpenSansPath}/OpenSans-Italic.eot?#iefix') format('embedded-opentype'),
       url('#{$OpenSansPath}/OpenSans-Italic.woff') format('woff'),
       url('#{$OpenSansPath}/OpenSans-Italic.ttf') format('truetype'),
       url('#{$OpenSansPath}/OpenSans-Italic.svg#OpenSansItalic') format('svg');
  font-weight: normal;
  font-style: italic;
}

// /* BEGIN Semibold */
// @font-face {
//   font-family: 'Open Sans';
//   src: url('#{$OpenSansPath}/Semibold/OpenSans-Semibold.eot');
//   src: url('#{$OpenSansPath}/Semibold/OpenSans-Semibold.eot?#iefix') format('embedded-opentype'),
//        url('#{$OpenSansPath}/Semibold/OpenSans-Semibold.woff') format('woff'),
//        url('#{$OpenSansPath}/Semibold/OpenSans-Semibold.ttf') format('truetype'),
//        url('#{$OpenSansPath}/Semibold/OpenSans-Semibold.svg#OpenSansSemibold') format('svg');
//   font-weight: 600;
//   font-style: normal;
// }
// /* END Semibold */

// /* BEGIN Semibold Italic */
// @font-face {
//   font-family: 'Open Sans';
//   src: url('#{$OpenSansPath}/SemiboldItalic/OpenSans-SemiboldItalic.eot');
//   src: url('#{$OpenSansPath}/SemiboldItalic/OpenSans-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
//        url('#{$OpenSansPath}/SemiboldItalic/OpenSans-SemiboldItalic.woff') format('woff'),
//        url('#{$OpenSansPath}/SemiboldItalic/OpenSans-SemiboldItalic.ttf') format('truetype'),
//        url('#{$OpenSansPath}/SemiboldItalic/OpenSans-SemiboldItalic.svg#OpenSansSemiboldItalic') format('svg');
//   font-weight: 600;
//   font-style: italic;
// }
// /* END Semibold Italic */

@font-face {
  font-family: 'Open Sans';
  src: url('#{$OpenSansPath}/OpenSans-Bold.eot');
  src: url('#{$OpenSansPath}/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
       url('#{$OpenSansPath}/OpenSans-Bold.woff') format('woff'),
       url('#{$OpenSansPath}/OpenSans-Bold.ttf') format('truetype'),
       url('#{$OpenSansPath}/OpenSans-Bold.svg#OpenSansBold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('#{$OpenSansPath}/OpenSans-BoldItalic.eot');
  src: url('#{$OpenSansPath}/OpenSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
       url('#{$OpenSansPath}/OpenSans-BoldItalic.woff') format('woff'),
       url('#{$OpenSansPath}/OpenSans-BoldItalic.ttf') format('truetype'),
       url('#{$OpenSansPath}/OpenSans-BoldItalic.svg#OpenSansBoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
}
