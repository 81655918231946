@charset "utf-8";

$logocolor: #a6d71c;
@import "open-sans";

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
    border: 0;
    font-family: inherit;
    font-size: 100%;
    font-style: inherit;
    font-weight: inherit;
    margin: 0;
    outline: 0;
    padding: 0;
    vertical-align: baseline;
}
:focus {
    outline: 0;
}
body {
    background: #fff;
    line-height: 1;
    padding: 0;
    min-width: 300px; // Absolute minimum for navigation
}
ol, ul {
    list-style: none;
}
table { // tables still need 'cellspacing="0"' in the markup
    border-collapse: separate;
    border-spacing: 0;
}
caption, th, td {
    font-weight: normal;
    text-align: left;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: "";
}
blockquote, q {
    quotes: "" "";
}
a img {
    border: 0;
}
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}


body, input, textarea {
    color: #373737;
    font: 15px "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 300;
    line-height: 1.625;
}

/* Headings */
h1,h2,h3,h4,h5,h6 {
    clear: both;
}
hr {
    background-color: #ccc;
    border: 0;
    height: 1px;
    margin-bottom: 1.625em;
}

/* Text elements */
p {
    margin-bottom: 1.625em;
}
ul, ol {
    margin: 0 0 1.625em 2.5em;
}
ul {
    list-style: square;
}
ol {
    list-style-type: decimal;
}
ol ol {
    list-style: upper-alpha;
}
ol ol ol {
    list-style: lower-roman;
}
ol ol ol ol {
    list-style: lower-alpha;
}
ul ul, ol ol, ul ol, ol ul {
    margin-bottom: 0;
}
dl {
    margin: 0 1.625em;
}
dt {
    font-weight: bold;
}
dd {
    margin-bottom: 1.625em;
}
strong {
    font-weight: bold;
}
cite, em, i {
    font-style: italic;
}
blockquote {
    font-weight: normal;
    // padding: 0 1em;
    // border-left: 0.5em solid #ddd;
    // color: #888;
    background: #eee;
    border-radius: 0.5em;
    padding: 1em 1.5em;
    margin-bottom: 1.625em;
    :last-child {
        margin-bottom: 0;
    }
}
blockquote em, blockquote i, blockquote cite {
    font-style: normal;
}
blockquote cite {
    color: #666;
    // font: 12px "Open Sans", sans-serif;
    font-weight: 300;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}
pre {
    // background: #f4f4f4;
    // font: 13px "Courier 10 Pitch", Courier, monospace;
    // line-height: 1.5;
    // margin-bottom: 1.625em;
    // overflow: auto;
    // padding: 0.75em 1.625em;
}
// code, kbd, samp, var {
//     font: 13px Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
// }
abbr, acronym, dfn {
    border-bottom: 1px dotted #666;
    cursor: help;
}
address {
    display: block;
    margin: 0 0 1.625em;
}
ins {
    background: #fff9c0;
    text-decoration: none;
}
sup,
sub {
    font-size: 10px;
    height: 0;
    line-height: 1;
    position: relative;
    vertical-align: baseline;
}
sup {
    bottom: 1ex;
}
sub {
    top: .5ex;
}
small {
    font-size: smaller;
}

/* Forms */
input[type=text],
input[type=password],
input[type=email],
input[type=url],
input[type=number],
textarea {
    background: #fafafa;
    -moz-box-shadow: inset 0 1px 1px rgba(0,0,0,0.1);
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.1);
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.1);
    border: 1px solid #ddd;
    color: #888;
}
input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=url]:focus,
input[type=number]:focus,
textarea:focus {
    color: #373737;
}
textarea {
    padding-left: 3px;
    width: 98%;
}
input[type=text],
input[type=password],
input[type=email],
input[type=url],
input[type=number] {
    padding: 3px;
}

/* Links */
a {
    color: #1982d1;
    text-decoration: none;
}
a:focus,
a:active,
a:hover {
    text-decoration: underline;
}

/* Assistive text */
.assistive-text,
.screen-reader-text {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    overflow: hidden;
    height: 1px;
    width: 1px;
}
#access a.assistive-text:focus,
.screen-reader-text:hover,
.screen-reader-text:active,
.screen-reader-text:focus {
    background: #eee;
    border-bottom: 1px solid #ddd;
    color: #1982d1;
    clip: auto !important;
    font-size: 12px;
    position: absolute;
    text-decoration: underline;
    top: 0;
    left: 7.6%;
}

code {
    padding: 0;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    margin: 0;
    font-size: 85%;
    background-color: rgba(0,0,0,0.04);
    border-radius: 3px;
    font-family: Monaco, Consolas, "Liberation Mono", Menlo, Courier, monospace;
}

///////////////////////////////////////////////////////////////////////////////


@mixin box-shadow() {
    @media not print {
        -webkit-box-shadow: 0 2px 6px rgba(100,100,100,.3);
        box-shadow: 0 2px 6px rgba(100,100,100,.3);
    }
}


body {
    @media not print {
        background: #e2e2e2;
    }
}
#page {
    background: #fff;
    margin: 0 auto 2em auto;
    max-width: 960px;
    @include box-shadow();
}

#iewarning {
    padding: 5px 10px;
    background: #fd0;
    text-align: center;
    font-weight: bold;
}

#branding {
    #title-wrapper {
        padding: 3.5em 3.5em 1em 3.5em;
        h1 {
            display: inline-block;
        }
        a {
            color: #111;
            font-size: 40px;
            line-height: 1.45em;
            letter-spacing: 0.1em;
            text-decoration: none;
            &:focus, &:hover, &:active {
                color: #1982d1;
            }
        }
        #site-logo {
            float: right;
            width: 61px;
            width: 58px;
            // height: 100%;
        }
    }
    #site-header-image {
        display: block;
        height: auto;
        width: 100%;
    }
}

#menu {
    display: block;
    float: left;
    margin: 0 auto 6px;
    width: 100%;
    text-align: center;
    border-top: 1px solid #bbb;
    table {
        font-size: 13px;
        // margin: 0 0 0 -0.8125em;
        padding: 0 7.6%;
        a {
            color: #111;
            display: block;
            line-height: 3.333em;
            padding: 0 1.2125em;
            text-decoration: none;
            &.icon::before {
                display: none;
            }
        }
        td:hover a, a:focus {
            color: #1982d1;
        }
        .menu-item-active {
            font-weight: bold;
        }
    }
}

#main {
    clear: both;
}

#primary {
    float: left;
    margin: 0 -26.4% 24px 0;
    width: 100%;
    .header-image {
        display: block;
        height: auto;
        width: 100%;
    }
}

#content {
    margin: 0 34% 0 7.6%;
    width: 58.4%;
}

.single-column #content {
    margin: 0 20.8% 0 20.8%;
}

#secondary {
    float: right;
    margin-right: 7.6%;
    margin-bottom: 24px;
    width: 18.8%;
    margin-top: 3em;
    img {
        margin: 5px 0;
        @include box-shadow();
        // box-shadow: 0 2px 6px rgba(100,100,100,.3);
    }
    h1 {
        color: #000;
        font-weight: bold;
        font-size: 120%;
        margin: 0 0 .8125em;
    }
    h2 {
        color: #000;
        font-weight: bold;
        margin: 0 0 .8125em;
    }
    h3 {
        font-size: 10px;
        letter-spacing: 0.1em;
        line-height: 2.6em;
        text-transform: uppercase;
    }
    @media print {
        display: none;
    }
}

#pagefooter {
    clear: both;
    padding: 24px 0;
    border-top: 1px solid #ddd;
    background: #f8f8f8;
    text-align: center;
    font-size: 12px;
    color: #666;
    line-height: 2em;
    a {
        color: #666;
        font-weight: bold;
    }
    @media print {
        display: none;
    }
}


@keyframes fadeina {
    0% { opacity: 0; }
    100%   { opacity: 1; }
}
@-webkit-keyframes fadeina {
    0% { opacity: 0; }
    100%   { opacity: 1; }
}
@keyframes fadeinb {
    0% { opacity: 0; }
    50% { opacity: 0; }
    100%   { opacity: 1; }
}
@-webkit-keyframes fadeinb {
    0% { opacity: 0; }
    50% { opacity: 0; }
    100%   { opacity: 1; }
}
@keyframes fadeinc {
    0% { opacity: 0; }
    66% { opacity: 0; }
    100%   { opacity: 1; }
}
@-webkit-keyframes fadeinc {
    0% { opacity: 0; }
    66% { opacity: 0; }
    100%   { opacity: 1; }
}
@keyframes fadeind {
    0% { opacity: 0; }
    75% { opacity: 0; }
    100%   { opacity: 1; }
}
@-webkit-keyframes fadeind {
    0% { opacity: 0; }
    75% { opacity: 0; }
    100%   { opacity: 1; }
}


article {
    margin-top: 3em;
    .entry-title {
        font-size: 30px;
        padding-bottom: 0.35em;
        &.compact {
            padding-bottom: 0;
            font-size: 25px;
        }
    }
    .article-image img {
        max-width: 100%;
    }
    .entry-meta {
        color: #666;
        clear: both;
        font-size: 12px;
        line-height: 18px;
    }
    .entry-content {
        h1 {
            color: #000;
            font-weight: bold;
            font-size: 130%;
            margin: 0em 0 .8125em;
        }
        h2 {
            color: #000;
            font-weight: bold;
            margin: 0 0 .8125em;
        }
        h3 {
            font-size: 10px;
            letter-spacing: 0.1em;
            line-height: 2.6em;
            text-transform: uppercase;
        }
        table {
            border-bottom: 1px solid #ddd;
            margin: 0 0 1.625em;
            width: 100%;
        }
        th {
            color: #666;
            font-size: 10px;
            font-weight: 500;
            letter-spacing: 0.1em;
            line-height: 2.6em;
            text-transform: uppercase;
        }
        td {
            border-top: 1px solid #ddd;
            padding: 6px 10px 6px 0;
        }
        hr {
            $height: 0.5em;
            background-color: transparent;
            background-image: url("images/line.png");
            background-repeat: repeat-x;
            background-size: auto $height;
            height: $height;
            // border: none;
            // border-bottom: 5px dotted #ddd;
            // height: 0;
            // background: none;
        }
        img {
            max-width: 100%;
            display: block;
            margin: 0 auto;
        }
        video {
            max-width: 100%;
            display: block;
            margin: 0 auto;
        }
        .colorized {
            color: darken($logocolor, 10%);
        }
        .indented {
            margin-left: 20%;
        }
        .center.large {
            font-size: 150%;
            padding: 1em 0 0.5em 0;
            text-align: center;
        }
        .center.fade {
            margin: 0.5em 0;
            text-align: right;
            font-size: 120%;
            &.fade0 {
                -webkit-animation: fadeina 1s linear;
                animation: fadeina 1s linear;
            }
            &.fade1 {
                -webkit-animation: fadeinb 2s linear;
                animation: fadeinb 2s linear;
            }
            &.fade2 {
                -webkit-animation: fadeinc 3s linear;
                animation: fadeinc 3s linear;
            }
            &.fade3 {
                -webkit-animation: fadeind 4s linear;
                animation: fadeind 4s linear;
            }
        }
        .floralswing {
            float: left;
            min-width: 120px;
            min-height: 120px;
            margin-right: 1em;
        }
    }
}

article .bigbtn-row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    > p {
        margin-bottom: 0;
    }
    .bigbtn {
        display: flex;
        align-items: end;
        aspect-ratio: 1/.8;
/*        background: lighten($logocolor, 40%);*/
        margin: 10px;
        vertical-align: bottom;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        hyphens: auto;
        border: 1px solid #ddd;
        span {
            display: inline-block;
            width: 90%;
            margin: 0;
            font-size: 1.3em;
            text-align: center;
            padding: .5em 5%;
            background: rgba(255,255,255,0.6);
        }
    }
    .bigbtn-list {
        flex-direction: column;
        margin: 10px;
    }
    @media (min-width: 651px) {
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        flex-wrap: wrap;
        > p {
            width: 50%;
        }
    }
}

article .book-mini {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    @media (min-width: 420px) {
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        .image {
            -webkit-flex: 0 0 26%;
            -ms-flex: 0 0 26%;
            flex: 0 0 26%;
            width: 26%;
            padding-right: 5%;
        }
        div.text {
            -webkit-flex: 1 1 69%;
            -ms-flex: 1 1 69%;
            flex: 1 1 69%;
            width: 69%;
        }
    }
}

article .book {
    p.image {
        float: left;
        margin-right: 5%;
        img {
            max-width: 100%;
            height: auto;
        }
    }
    &::after {
        content: "";
        display: table;
        clear: both;
    }
}

article .book-multilanguage {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    -ms-align-items: flex-end;
    align-items: flex-end;
    p {
        -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        text-align: center;
        padding: 5px;
        // max-width: 20%;
        // img {
        //     width: 100%;
        // }
    }
}

article .key-breadcrumb > p {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    font-size: 80%;
    a {
        display: none;
        color: #777;
        img {
            display: inline-block;
            padding: 4px 1em 4px 0;
            width: 40px;
            height: 40px;
            vertical-align: middle;
        }
        &:first-child, &:nth-child(2) {
            display: block;
        }
        &:last-child {
            font-weight: bold;
        }
    }
    @media (min-width: 420px) {
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        background: #f0f0f0;
        a {
            display: block;
            padding: 3px;
            box-sizing: border-box;
            text-align: center;
            -webkit-flex: 0 0 20%;
            -ms-flex: 0 0 20%;
            flex: 0 0 20%;
            position: relative;
            img {
                display: block;
                padding: 3px;
                margin: 0 auto;
            }
            &::after {
                content: " ";
                position: absolute;
                top: 24px;
                right: -10%;
                left: 90%;
                border-top: 2px solid #777;
            }
            &:last-child::after { display: none; }
        }
    }
    
}

article .hover_el {
	opacity: 0;
	fill: gray;
	&:hover{
		opacity: 0.2;
	}
}

article x-moon-calendar {
    display: block;
    margin: 0 0 1.625em;
    .moon-calendar-head {
        margin: 0;
        td {
            text-align: center;
            padding: 3px 0;
            border-top: none;
            &.moon-calendar-month {
                font-weight: bold;
                font-size: 120%;
            }
            &.moon-calendar-arrow-cell {
                width: 24px;
                padding: 0 6px;
                vertical-align: middle;
                span {
                    display: inline-block;
                    background-image: url(images/calendar-arrows.png);
                    background-repeat: no-repeat;
                    background-size: 48px 24px;
                    width: 24px;
                    height: 24px;
                }
                &:last-child span {
                    background-position: -24px 0;
                }
            }
        }
    }
    @mixin icon-sprite($x, $y) {
        background-position: $x / 2 $y / 2;
        background-size: 96px 80px;
        width: 16px;
        height: 16px;
    }
    .icon {
        margin: 0 auto;
        background-image: url(images/calendar-icons.png);
        background-repeat: no-repeat;
        display: block;
        vertical-align: sub;
        &.icon-a1 {
            @include icon-sprite(0, 0);
        }
        &.icon-a2 {
            @include icon-sprite(-32px, 0);
        }
        &.icon-arrow {
            @include icon-sprite(-64px, 0);
        }
        &.icon-m1 {
            @include icon-sprite(-96px, 0);
        }
        &.icon-m2 {
            @include icon-sprite(-128px, 0);
        }
        &.icon-m3 {
            @include icon-sprite(0, -32px);
        }
        &.icon-m4 {
            @include icon-sprite(-32px, -32px);
        }
        &.icon-mn1 {
            @include icon-sprite(-64px, -32px);
        }
        &.icon-mn2 {
            @include icon-sprite(-96px, -32px);
        }
        &.icon-p1 {
            @include icon-sprite(-128px, -32px);
        }
        &.icon-p2 {
            @include icon-sprite(0, -64px);
        }
        &.icon-p3 {
            @include icon-sprite(-32px, -64px);
        }
        &.icon-p4 {
            @include icon-sprite(-64px, -64px);
        }
        &.icon-s10 {
            @include icon-sprite(-96px, -64px);
        }
        &.icon-s11 {
            @include icon-sprite(-128px, -64px);
        }
        &.icon-s12 {
            @include icon-sprite(0, -96px);
        }
        &.icon-s1 {
            @include icon-sprite(-32px, -96px);
        }
        &.icon-s2 {
            @include icon-sprite(-64px, -96px);
        }
        &.icon-s3 {
            @include icon-sprite(-96px, -96px);
        }
        &.icon-s4 {
            @include icon-sprite(-128px, -96px);
        }
        &.icon-s5 {
            @include icon-sprite(0, -128px);
        }
        &.icon-s6 {
            @include icon-sprite(-32px, -128px);
        }
        &.icon-s7 {
            @include icon-sprite(-64px, -128px);
        }
        &.icon-s8 {
            @include icon-sprite(-96px, -128px);
        }
        &.icon-s9 {
            @include icon-sprite(-128px, -128px);
        }
        &.icon-v1 {
            @include icon-sprite(-160px, 0);
        }
    }
    .moon-calendar-wrapper {
        border-bottom: 1px solid #ddd;
        &::after {
            content: "";
            display: table;
            clear: both;
        }
        table.moon-calendar {
            border: none;
            margin: 0;
            td {
                padding: 0;
                border: 0;
                vertical-align: middle;
                max-width: 70px;
                &:first-child {
                    border-left: 2px solid transparent;
                    padding-left: 4px;
                }
                &.iconcell {
                    width:  24px;
                    min-width: 24px;
                }
            }
            .current td {
                background: lighten($logocolor, 40%);
            }
            .planttime td:first-child {
                border-left: 2px solid #A2D630;
            }
        }
    }
    .moon-calendar-legend {
        border-bottom: 1px solid #ddd;
        &::after {
            content: "";
            display: table;
            clear: both;
        }
        div {
            float: left;
            width: 9em;
            font-size: 90%;
            margin-left: 10px;
            .icon {
                margin: 0 5px;
                display: inline-block;
            }
        }
    }
    &::after {
        content: "";
        display: table;
        clear: both;
    }
}

#primary.layout-start {
    margin-bottom: 0;
    padding-bottom: 24px;
    background-image: url("images/Kuerbissaemling.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    .single-column & #content {
        margin: 0 7.6% 0 7.6%;
        width: 84.8%;
    }
    article {
        .clear::after {
            content: "";
            display: table;
            clear: both;
        }
        .box {
            padding: 1em;
            margin: 0 0 1.625em;
            border-radius: 0.2em;
            background-color: #fff;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            p:last-child {
                margin-bottom: 0;
            }
            @include box-shadow();
            border-radius: 1px;
        }
        .left {
            float: left;
        }
        .right {
            float: right;
        }
        .floating-image {
            width: 25%;
            img {
                @include box-shadow();
                border-radius: 1px;
            }
        }
        .large {
            width: 60%;
        }
        .classic {
            width: 69%;
            margin-left: auto;
            margin-right: auto;
        }
        .align-right {
            text-align: right;
        }
    }
}

#primary.layout-about {
    float: none;
    .about-image {
        position: relative;
        img {
            position: absolute;
            bottom: 1.5em;
            width: 35%;
            left: 10%;
            padding: 0.5em;
            border-radius: 8px;
            background-color: #fff;
        }
    }
    #content {
        margin-bottom: 24px;
    }
    article {
        .about-flex {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-align-items: flex-end;
            -ms-align-items: flex-end;
            align-items: flex-end;
            & > div {
                display: block;
                // padding: 5px;
            }
            .about-main {
                -webkit-flex: 1 1 auto;
                -ms-flex: 1 1 auto;
                flex: 1 1 auto;
                -webkit-order: 2;
                -ms-flex-order: 2;
                border-left: 2em solid transparent;
                table {
                    // padding-left: 2em;
                    border: none;
                    td {
                        border: none;
                        &:first-child {
                            color: darken($logocolor, 10%);
                        }
                    }
                }
                // margin-left: 2em;
                // ul {
                //     list-style: none;
                //     margin-left: 0;
                //     li {
                //         padding-left: 3em;
                //         text-indent: -4em;
                //         padding-bottom: 0.5em;
                //     }
                //     li::before {
                //         content: attr(data-year);
                //         padding-right: 1em;
                //         width: 3em;
                //         color: darken($logocolor, 10%);
                //         float: left;
                //     }
                // }
            }
            .about-image-bottom {
                -webkit-flex: 1 0 auto;
                -ms-flex: 1 0 auto;
                flex: 1 0 auto;
                width: 35%;
                margin-bottom: 40px;
                font-size: 110%;
                img {
                    width: 100%;
                }
            }
        }
    }
}

#primary.layout-list {
    hr {
        $height: 0.5em;
        background-color: transparent;
        background-image: url("images/line.png");
        background-repeat: repeat-x;
        background-size: auto $height;
        height: $height;
        margin: 1.625em 0;
        &.compact {
            margin: 0.7em 0;
        }
    }
    article.mini {
        margin-top: 0;
    }
}

@media (min-width: 420px) {
    article x-moon-calendar .moon-calendar-wrapper table.moon-calendar {
        float: left;
        width: 49%;
        &:last-child {
            margin-left: 2%;
        }
    }
}

@-ms-viewport {
    width: device-width;
}
@viewport {
    width: device-width;
}


// Sidebar below
@media (max-width: 800px) {
    #content, .single-column #content {
        margin: 0 7.6%;
        width: auto;
    }

    #primary.layout-start article .classic {
        width: 100%;
    }

    #secondary {
        float: none;
        margin: 0 7.6%;
        width: auto;
    }

}

@media (max-width: 419px) {
    article .book.book-large p.image {
        float: none;
        width: 100%;
    }
}

@media print {
    #branding {
        #title-wrapper {
            padding: 1.5em 1em;
            text-align: left;
        }
        #site-title {
            a {
                font-size: 32px;
            }
        }
        #site-logo {
            display: block;
        }
        #menu {
            display: none;
        }
    }
}
